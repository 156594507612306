<template>
  <div>
    <div class="flix-form-group">
      <component :is="'Form'+form[index].type" :form="form" :index="index" />
    </div>
    <div class="flix-form-group">
      <formWidth :data="{label: 'Breite', value: form[index].columnName.toString(), values: getWidth}" :callback="setWidth"/>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    Formheader () { return import('@/components/formBuilder/editor/parts/header') },
    Formparagraph () { return import('@/components/formBuilder/editor/parts/paragraph') },
    Formtext () { return import('@/components/formBuilder/editor/parts/text') },
    Formtextarea () { return import('@/components/formBuilder/editor/parts/text') },
    Formradio () { return import('@/components/formBuilder/editor/parts/radio') },
    Formcheckbox () { return import('@/components/formBuilder/editor/parts/radio') },
    Formagb () { return import('@/components/formBuilder/editor/parts/agb') },
    formWidth () { return import('@/components/formBuilder/parts/radio') }
  },
  props: { form: Array, index: Number },
  data () {
    return {
      getWidth: [
        { label: Math.round((12 / 12 * 100)) + '%', value: '12' },
        { label: Math.round((8 / 12 * 100)) + '%', value: '8' },
        { label: Math.round((6 / 12 * 100)) + '%', value: '6' },
        { label: Math.round((4 / 12 * 100)) + '%', value: '4' },
        { label: Math.round((3 / 12 * 100)) + '%', value: '3' }
      ]
    }
  },
  methods: {
    setWidth (data) {
      this.form[this.index].columnName = data
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
